.hide {
  display: none;
}

/* ================================================NAVBAR==================================================== */

@keyframes opensearch {
  0% {
    width: 80%;
    margin-left: 0;
  }

  100% {
    width: 70%;
    margin-left: 1rem;
  }
}

@keyframes closesearch {
  0% {
    width: 70%;
    margin-left: 1rem;
  }

  100% {
    width: 80%;
    margin-left: 0;
  }
}

@keyframes openfilters {
  0% {
    background-color: grey;
    top: -5rem;
    opacity: 0;
  }

  100% {
    background-color: white;
    top: 6rem;
    opacity: 1;
  }
}

@keyframes opensidebar {
  0% {
    margin-left: -10rem;
  }

  100% {
    margin-left: 0;
  }
}

@keyframes opencart {
  0% {
    margin-right: -10rem;
  }

  100% {
    margin-right: 0rem;
  }
}

.nav {
  position: relative;
}

.mobile-navbar {
  padding: 1.2rem;
  position: fixed;
  background-color: white;
  top: 0;
  left: 0;
  right: 0;
}

.mobile-nav-top {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.location {
  cursor: pointer;
  font-weight: 700;
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
}

.cart-icon {
  color: white;
  background-color: black;
  margin-left: auto;
  padding: 0.5rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.mobile-nav-bottom {
  display: flex;
  align-items: center;
}

.search {
  display: flex;
  align-items: center;
  background-color: rgb(233, 233, 233);
  border-radius: 20px;
  padding: 0.6rem 1rem;
}

.search-open {
  width: 70%;
  margin-left: 1rem;
  animation-name: opensearch;
  animation-duration: 0.2s;
}

.search-closed {
  width: 80%;
  animation-name: closesearch;
  animation-duration: 0.2s;
}

.search form {
  width: 100%;
}

.d-search form {
  width: 100%;
}

.search input {
  width: 100%;
  margin-left: 0.5rem;
  font-size: 1rem;
}

.sort-icon {
  margin-left: auto;
}

.categories,
.sort {
  min-height: 50vh;
  background-color: white;
  overflow-y: scroll;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  top: 7rem;
  animation-name: openfilters;
  animation-duration: 0.3s;
  padding: 3.2rem 1.2rem;
}

.categ-title,
.sort-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

.sort-title div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.category {
  margin-bottom: 2rem;
}

.category div {
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-top: 1rem;
  gap: 1.5rem;
  font-size: 1.2rem;
  font-weight: 500;
  padding-bottom: 1rem;
}

.sort-param input {
  margin-right: 1rem;
  cursor: pointer;
}

.sort-param {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.desktop-navbar {
  display: none;
  position: fixed;
  background-color: white;
  top: 0;
  right: 0;
  left: 0;
  z-index: 100;
}

.d-nav {
  display: flex;
  padding: 1.5rem 3rem;
  align-items: center;
}

.logo {
  width: 2.5rem;
  object-fit: contain;
  margin-left: 3rem;
  margin-right: 2rem;
}

.user {
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: 2rem;
}

.cart {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.cart p {
  display: flex;
  align-items: center;
}

.cart span {
  display: flex;
  align-items: center;
}

.d-search {
  margin: auto;
  display: flex;
  align-items: center;
  gap: 1rem;
  background-color: rgb(233, 233, 233);
  padding: 0.5rem;
  width: 30%;
  border-radius: 20px;
}

.d-search input {
  width: 90%;
}

.sign-in {
  font-size: 1.1rem;
  font-weight: 700;
}

.cart {
  background-color: black;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 30px;
  font-size: 1.1rem;
  font-weight: 700;
}

.d-categories {
  min-height: 50vh;
  background-color: white;
  overflow-y: scroll;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  top: 6rem;
  animation-name: openfilters;
  animation-duration: 0.3s;
  padding: 1.2rem;
}

.close-search {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.side-bar,
.side-cart {
  position: fixed;
  height: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  animation-name: opensidebar;
  animation-duration: 0.5s;
}

.side-bar {
  display: flex;
}

.side-cart {
  display: flex !important;
  animation-name: opencart !important;
  animation-duration: 0.5s;
}

.side-cart .bar {
  flex: 2;
}

.close-cart {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.close-cart p {
  font-size: 2rem;
  font-weight: 700;
}

.s-cart {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 300px;
  margin: auto;
}

.bar {
  height: 100%;
  flex: 1;
  background-color: white;
  padding: 2rem 1rem;
  padding-left: 2.5rem;
}

.filler {
  flex: 0.1;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  display: block;
}

.cart-filler {
  display: none;
}

.close-bar {
  text-align: right;
}

.bar-signin {
  display: flex;
  gap: 2rem;
  margin-top: 3rem;
  cursor: pointer;
}

.bar-options {
  padding-top: 6rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  font-weight: 700;
}

.bar-options p {
  cursor: pointer;
}

.bar-ads {
  margin-top: 7rem;
}

.ba-1 {
  display: flex;
  gap: 1rem;
  font-weight: 500;
}

.ba-1 img {
  width: 2.5rem;
  object-fit: contain;
}

.ba-2 {
  display: flex;
  margin-top: 1rem;
  gap: 1rem;
}

.ba-2 img,
.f-apps img {
  width: 2rem;
  object-fit: cover;
}

.ba-2 button,
.f-apps button {
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: black;
  color: white;
  font-weight: 700;
  padding: 0.5rem;
  border-radius: 20px;
}

@media only screen and (min-width: 1024px) {
  .cart-filler,
  .filler {
    flex: 4;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    display: block;
  }

  .desktop-navbar {
    display: block;
  }

  .mobile-navbar {
    display: none;
  }

  .side-bar,
  .side-cart {
    display: flex;
  }
}

/* ============================================================TAB============================================================= */
.tabs {
  margin-top: 8rem;
}

.tabs-container {
  margin: 1.5rem 1rem;
  display: flex;
  gap: 3rem;
}

.tab {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 600;
}

.tab-active {
  background-color: black;
  color: white;
  padding: 1rem 2rem;
  border-radius: 50px;
}

@media only screen and (min-width: 1024px) {
  .tabs {
    margin-top: 6rem;
  }

  .tabs-container {
    margin: 1.5rem 3rem;
  }
}

/* ============================================================ FOOD FILTERS ============================================================= */
.food-filters {
  padding: 2rem;
  display: flex;
  gap: 2rem;
  overflow-x: scroll;
}

.f-filter {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media only screen and (min-width: 1024px) {
  .food-filters {
    padding: 3rem 5rem;
  }
}

/* ============================================================= BANNER ========================================================= */
.banners {
  display: flex;
  padding: 1rem;
  gap: 2rem;
  overflow-x: scroll;
}

::-webkit-scrollbar {
  display: none;
}

.banner {
  cursor: pointer;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

@media only screen and (min-width: 720px) {
  .feed {
    width: 47%;
  }
  .banner {
    width: 47%;
  }
}

@media only screen and (min-width: 1024px) {
  .banners {
    padding: 2rem 5rem;
  }
}

/* ============================================================ FEED ============================================================= */
.desktop-feed {
  display: flex;
  width: 100vw;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.sticky-menu {
  display: none;
  width: 250px;
  height: 250px;
  position: sticky;
  top: 8rem;
  bottom: 3rem;
  padding: 1rem 3rem;
  padding-left: 5rem;
  /* z-index: -1; */
}

.feed-cont {
  padding: 0 1rem;
  flex: 5;
}

.feed-items {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  padding: 1rem;
  gap: 2rem;
  /* justify-content: center; */
}

.feed {
  width: 100%;
  cursor: pointer;
}

.sort-param {
  cursor: pointer;
}

.smh-1 {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

.smh-2 {
  margin-top: 2rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.feed-img {
  object-fit: cover;
  width: 100%;
  height: 150px;
  border-radius: 10px;
}

.restaurant {
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 0.5rem;
}

.restaurant span {
  text-transform: uppercase;
}

.r-foods {
  text-transform: capitalize;
  font-size: 0.8rem;
  color: grey;
}

.ratings {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 0.9rem;
}

.star {
  display: flex;
  align-items: center;
}

@media only screen and (min-width: 720px) {
  .feed {
    width: 47%;
  }
}

@media only screen and (min-width: 1024px) {
  .sticky-menu {
    display: block;
  }
}

@media only screen and (min-width: 1280px) {
  .feed {
    width: 30%;
  }
}

/* =======================================================================FOOTER======================================================================== */

.footer {
  margin-top: 10rem;
  padding: 0 1rem;
  margin-bottom: 15rem;
}

.footer-flexbox {
  display: flex;
  justify-content: space-between;
  display: none;
}

.f-logo {
  display: flex;
  align-items: center;
  font-size: 2rem;
  gap: 0.5rem;
  margin-top: 5rem;
}

.f-logo img {
  width: 3rem;
}

.f-logo p {
  font-family: Poetsen One, serif;
  color: green;
}

.socials {
  flex: 2;
}

.socials-subtext {
  font-size: 1.1rem;
  margin-top: 1rem;
}

.f-apps {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

.socials-logos {
  margin-top: 2rem;
  display: flex;
  gap: 2rem;
}

.s-logo {
  color: white;
  background-color: black;
  cursor: pointer;
  display: flex;
  padding: 0.5rem;
  border-radius: 50%;
}

.more {
  flex: 3;
  margin-left: 5rem;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.more-1 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.m1 {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.m1-header {
  font-size: 1.2rem;
  font-weight: 700;
}

.m1-link {
  color: green;
  cursor: pointer;
}

.more-2 {
  text-align: right;
}

.more-2 img {
  cursor: pointer;
}

.legal {
  display: flex;
  margin-top: 3rem;
  gap: 1rem;
  color: green;
  font-weight: 500;
}

.legal p {
  cursor: pointer;
}

.copyright {
  text-align: center;
  margin-top: 5rem;
  font-size: 0.9rem;
}

@media only screen and (min-width: 1000px) {
  .mobile-footer {
    display: none;
  }

  .footer-flexbox {
    display: flex;
  }

  .more-1 {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  .footer {
    padding: 0 5rem;
  }

  .socials-logos {
    margin-top: 8rem;
  }

  .f-logo {
    margin-top: 0;
  }

  .f-apps {
    justify-content: start;
    gap: 3rem;
  }

  .legal {
    gap: 2rem;
    margin-top: 0;
  }
}

.floating-btns {
  color: white;
}

.floating-btns div {
  display: flex;
  width: fit-content;
  padding: 0.5rem;
  border-radius: 50%;
  margin: 1.5rem;
}

.chat {
  background-color: green;
  position: fixed;
  bottom: 0;
  left: 0;
  cursor: pointer;
}

.float-cart {
  display: none;
  cursor: pointer;
}

@media only screen and (min-width: 1024px) {
  .float-cart {
    background-color: black;
    position: fixed;
    display: block;
    bottom: 0;
    right: 0;
  }
}
